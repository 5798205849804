import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/support/i18n'
import axiosPlugin from '@/support/axios'
import { sync } from 'vuex-router-sync'
import vuetify from '@/support/vuetify'
import mixins from './mixins'
import Toasted from 'vue-toasted'
import Vuelidate from 'vuelidate'
import VueWindowSize from 'vue-window-size'
import '@/support/hotjar'
import VueClipboard from 'vue-clipboard2'
import { VueMaskDirective } from 'v-mask'
import LoadScript from 'vue-plugin-load-script'
Vue.use(LoadScript)

const HIRE_API_URL = process.env.VUE_APP_HIRE_API

let plooralGlobal = localStorage.getItem('plooral-global')

async function bootstrap () {
  let syncData = {}
  let clear = true
  if (plooralGlobal) {
    plooralGlobal = JSON.parse(plooralGlobal)
    if (plooralGlobal) {
      const resOne = await fetch(HIRE_API_URL + 'v1/auth/data', {
        method: 'GET',
        headers: {
          'x-access-token': plooralGlobal?.token
        }
      })
      const userData = await resOne.json()

      if (resOne && resOne.status === 403) {
        localStorage.clear()
        window.location.href = `${window.location.origin}/login`
        return
      }

      if (userData.user && userData.user.companies) {
        userData.user.companies.forEach(company => {
          if (company.avatar.includes('empty')) {
            company.avatar = '/assets/images/company-default.png'
          }
        })
      }
      syncData.user = userData.user
      syncData.token = userData.token
      if (syncData && syncData.user && syncData.user.isSupport) {
        const splitPath = window.location.pathname.split('/').filter(i => !!i)
        if (splitPath[0] === 'c' && !!splitPath[1]) {
          const supportResData = await fetch(HIRE_API_URL + `v1/auth/data?subdomain=${splitPath[1]}`, {
            method: 'GET',
            headers: {
              'x-access-token': plooralGlobal?.token
            }
          })
          const supportData = await supportResData.json()
          syncData.user = supportData.user
        }
      }
      if (userData && userData.user) {
        const syncBody = { ...userData.user }
        if (syncData.user.isSupport) {
          syncBody.companies = []
        }
        syncData.workspaces = []

        clear = false
      }
    }
  }
  if (clear) {
    localStorage.clear()
    syncData = {}
  }

  VueClipboard.config.autoSetContainer = true
  Vue.use(VueClipboard)
  Vue.config.productionTip = false

  Vue.directive('mask', VueMaskDirective)
  Vue.use(axiosPlugin, { store, router })
  Vue.use(Vuelidate)
  Vue.use(Toasted, {
    duration: 3000,
    position: 'bottom-right',
    iconPack: 'mdi',
    className: 'notification-toast'
  })
  Vue.use(VueWindowSize)

  Vue.mixin(mixins)

  sync(store, router)

  const app = new Vue({
    router,
    store,
    i18n,
    vuetify,
    syncData,
    render: h => h(App)
  }).$mount('#app')

  if (process.env.VUE_APP_DEV_MODE) window.$appRef = app
}
bootstrap()
