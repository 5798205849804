/* eslint-disable no-undef */
import { http, hireHttp } from '@/support/axios'
import { parse } from '@/support/payloadParser'
import staticWorkspaces from '@/support/static-workspaces/workspaces.json'

export default {
  state: {
    userToken: null,
    user: null,
    languageIsLoaded: false,
    language: 'pt-BR',
    workspaceInvitesRemindLater: false,
    userApplicationInvites: [],
    userUnreadMessages: [],
    userMatches: [],
    closedMatches: [],
    userFavoritePosition: [],
    hasBeenSynchronized: false,
    userProfile: {},
    wizard: {
      active: false,
      completed: false,
      theme: false
    },
    lastWorkspaceSelected: null,
    userUnderstood: false,
    userResumeType: '',
    shownModalChangeWorkspace: {
      individual: false,
      business: false,
      aggregator: false
    }
  },
  getters: {
    getHasBeenSynchronized: (state) => state.hasBeenSynchronized,
    getShownModalChangeWorkspace: (state) => state.shownModalChangeWorkspace,
    getUserToken: (state) => state.userToken,
    getUser: (state) => state.user,
    getWorkspaceInvitesRemindLater: (state) => state.workspaceInvitesRemindLater,
    getLanguageIsLoaded: state => state.languageIsLoaded,
    getUserApplicationInvites: state => state.userApplicationInvites,
    getUserUnreadMessages: state => state.userUnreadMessages,
    getUserMatches: state => state.userMatches,
    getUserClosedMatches: state => state.userClosedMatches,
    getUserFavoritePosition: state => state.userFavoritePosition,
    getUserProfile: (state) => state.userProfile,
    getLanguageList: (state) => state.languageList,
    getWizard: (state) => state.wizard,
    getLastWorkspaceSelected: (state) => state.lastWorkspaceSelected,
    getUserUnderstood: (state) => state.userUnderstood,
    getUserResumeType: (state) => state.userResumeType
  },
  mutations: {
    setShownWorkscapeWelcome (state, type) {
      if (type) state.shownModalChangeWorkspace[type] = true
      const plooralGlobal = JSON.parse(localStorage.getItem('plooral-global'))
      localStorage.setItem('plooral-global', JSON.stringify({ ...plooralGlobal, visitedWorkspaceTypes: state.shownModalChangeWorkspace }))
    },
    updateUserToken (state, token) {
      state.userToken = token
    },
    updateUserApplicationInvites (state, invites) {
      state.userApplicationInvites = invites
    },
    updateUserMessages (state, unreadMessages) {
      state.userUnreadMessages = unreadMessages
    },
    updateUserMatches (state, matches) {
      state.userMatches = matches
    },
    updateUserClosedMatches (state, closedMatches) {
      state.userClosedMatches = closedMatches
    },
    syncronizeUserData (state, data) {
      state.hasBeenSynchronized = data
    },
    updateUserFavoritePosition (state, favoritePosition) {
      state.userFavoritePosition = favoritePosition.map(match => ({
        position: {
          id: match.positionId,
          title: match.positionTitle,
          company: {
            name: match.companyName,
            logo: match.logo,
            image: match.image
          },
          positionUrl: match.positionUrl,
          isFavorite: true
        }
      }))
    },
    updateUser (state, value) {
      if (value && value.clear) {
        state.user = null
      } else {
        state.user = { ...state.user, ...value }
      }
    },
    updateUserTos (state, subdomain) {
      const data = new Date()
      state.user.tosAcceptedAt = data
      state.user.companies = state.user.companies.map(company => {
        if (company.subdomain === subdomain) return { ...company, userTosAcceptedAt: data }
        return { ...company }
      })
    },
    updateResumeType (state, payload) {
      state.userResumeType = payload
    },
    updateUserSkills (state, payload) {
      state.user.skills = payload
      state.user = { ...state.user }
    },
    updateUserSkill (state, payload) {
      const skill = state.user.skills.find(skill => skill.title === payload.skill.title)
      skill.level = payload.newLevel
    },
    removeUserSkill (state, skill) {
      const skillIndex = state.user.skills.findIndex(userSkill => userSkill.title === skill.title)
      state.user.skills[skillIndex].deleted = true
      state.user = { ...state.user }
    },
    setWorkspaceInvitesRemindLater (state, value) {
      state.workspaceInvitesRemindLater = value
    },
    setLanguageIsLoaded (state, value) {
      state.languageIsLoaded = value
    },
    setUserProfile (state, value) {
      state.userProfile = value
    },
    setLanguageList (state, value) {
      state.languageList = value
    },
    updatePositionSuggestionsHome (state, positionsuggestions) {
      state.positionSuggestionsHome = positionsuggestions
    },
    updateWizardSteps (state, step) {
      state.wizard = { ...state.wizard, ...step }
    },
    updateLastWorkspaceSelected (state, value) {
      state.lastWorkspaceSelected = value
    },
    updateUnderstood (state, value) {
      state.userUnderstood = value
    }
  },
  actions: {
    attemptGetUserApplicationInvites ({ commit }, aggregator) {
      let queryString = ''
      if (aggregator) {
        const selectedWorkspace = aggregator.aggregator
        if (selectedWorkspace && selectedWorkspace.workSpaceType === 'aggregator') {
          queryString = `?aggregator=${selectedWorkspace.CompanyDomain}`
        }
      }
      return hireHttp.get('v1/applications/invites' + queryString).then(({ data }) => {
        commit('updateUserApplicationInvites', data)
        return data
      })
    },
    attemptGetUserMessages ({ commit }, aggregator) {
      let queryString = ''
      if (aggregator) {
        const selectedWorkspace = aggregator.aggregator
        if (selectedWorkspace && selectedWorkspace.workSpaceType === 'aggregator') {
          queryString = `?aggregator=${selectedWorkspace.CompanyDomain}`
        }
      }
      return hireHttp.get(`v1/messages${queryString}`).then(({ data }) => {
        commit('updateUserMessages', data)
        return data
      })
    },
    attemptGetUserMatches ({ commit }, aggregator) {
      let queryString = ''
      if (aggregator) {
        const selectedWorkspace = aggregator.aggregator
        if (selectedWorkspace.workSpaceType === 'aggregator') {
          queryString = `?aggregator=${selectedWorkspace.CompanyDomain}`
          return hireHttp.get('v1/matches' + queryString).then(({ data }) => {
            commit('updateUserMatches', data)
            return data
          })
        } else {
          return hireHttp.get('v1/matches?messagesFrom=' + (new Date()).toJSON()).then(({ data }) => {
            commit('updateUserMatches', data)
            return data
          })
        }
      } else {
        return hireHttp.get('v1/matches?messagesFrom=' + (new Date()).toJSON()).then(({ data }) => {
          commit('updateUserMatches', data)
          return data
        })
      }
    },
    attemptGetUserClosedMatches ({ commit }, aggregator) {
      let queryString = ''
      if (aggregator) {
        const selectedWorkspace = aggregator.aggregator
        if (selectedWorkspace.workSpaceType === 'aggregator') {
          queryString = `?aggregator=${selectedWorkspace.CompanyDomain}`
        }
      }
      return hireHttp.get('v1/oldmatches' + queryString).then(({ data }) => {
        commit('updateUserClosedMatches', data)
        return data
      })
    },
    attemptGetUserFavoritePosition ({ commit }, aggregator) {
      let queryString = ''
      if (aggregator) {
        const selectedWorkspace = aggregator.aggregator
        if (selectedWorkspace.workSpaceType === 'aggregator') {
          queryString = `?aggregator=${selectedWorkspace.CompanyDomain}`
        }
      }

      return hireHttp.get('v1/favorite' + queryString).then(({ data }) => {
        commit('updateUserFavoritePosition', data)
        return data
      })
    },
    attemptDeleteCandidature (_, payload) {
      hireHttp.delete('v1/matches/' + payload.id).then(() => {
        if (payload.reason) hireHttp.post(`v1/matches/${payload.id}/feedback`, { feedback: payload.reason })
      })
    },
    attemptGetUserInfo ({ commit }) {
      return http.get('v1/myself/info').then(({ data }) => {
        commit('updateUser', data)
        return data
      }).catch(() => {
        return {}
      })
    },
    attemptGetHireUserInfo (_) {
      return hireHttp.get('v1/auth/data')
    },
    attemptDeleteAuthToken (_) {
      return hireHttp.delete('v1/auth')
    },
    attemptPerformLogin ({ commit }, body) {
      const aggregators = JSON.parse(localStorage.getItem('availableAggregators'))
      const workspaces = []
      staticWorkspaces.filter(workspace => {
        return workspace.subdomain === body.workspace || (aggregators && aggregators.length && (aggregators.findIndex(aggr => aggr.subdomain === workspace.subdomain) !== -1))
      }).forEach(workspace => {
        workspaces.push(workspace)
      })

      return hireHttp.post('v1/auth', body).then(response => {
        localStorage.setItem('availableAggregators', JSON.stringify(workspaces))
        commit('updateUserToken', response.data.token)
        const individual = { ...response.data.user, type: 'individual' }
        commit('updateUser', individual)
        response.data.user.companies && response.data.user.companies.forEach(company => {
          workspaces.push({ ...company, type: 'business' })
        })
        workspaces.push(individual)
        commit('updateUserWorkspaces', { force: true, workspaces: workspaces })
        commit('updateIndividualWorkspace', individual)
        // commit('setSelectedWorkspace', individual)
        return response
      })
    },
    attemptSubmitContentLanguagePreference (_, { workspaceId, languages }) {
      return http.post(`v1/workspaces/${workspaceId}/languages`, { languages })
    },
    attemptSetInitialConfiguration ({ state }, { userInitialSettingId, status }) {
      state.user.pendingInitialSettings.forEach(setting => {
        if (setting.id === userInitialSettingId) {
          setting.status = status
        }
      })
      return http.put(`v1/myself/initial-settings/${userInitialSettingId}`, { status })
    },
    attemptChangeUserLocale (_, data) {
      return http.put(`v1/myself/language/${data}`)
    },
    attemptGetUserProfile ({ commit }) {
      return hireHttp.get('v1/profile').then(({ data }) => {
        commit('setUserProfile', data)
        return data
      })
    },
    attemptGetLanguages ({ commit }, language = 'pt') {
      return hireHttp.get('v1/languages?lang=' + language).then(({ data }) => {
        const obj = {}
        data.forEach((el) => {
          obj[el.id] = el.name
        })
        commit('setLanguageList', obj)
        return obj
      })
    },
    attemptVerifyEmail (_, email) {
      return hireHttp.get('v1/auth-me?email=' + email)
    },
    attemptGetUniversity (_, universityName) {
      return hireHttp.get('v1/schools?query=' + universityName).then(({ data }) => {
        return data
      })
    },
    attemptGetCourse (_, courseInfo) {
      return hireHttp.get(`v1/courses?lang=${courseInfo.language}&query=` + courseInfo.name).then(({ data }) => {
        return data
      })
    },
    attemptGetCompanies (_, companyName) {
      return hireHttp.get('v1/companies?lang=pt&query=' + companyName).then(({ data }) => {
        return data
      })
    },
    attemptGetJobTitle (_, jobTitle) {
      return hireHttp.get('v1/jobtitles?lang=pt&query=' + jobTitle).then(({ data }) => {
        return data
      })
    },
    attemptDeleteUserProfile (_, data) {
      const parseUrl = (object) => {
        let finalurl = `?filter=${object.filter.replace(' ', '')}&`
        delete object.filter
        Object.keys(object).map(key => {
          finalurl += `${key}=${object[key].join(',')}&`
        })
        return finalurl.slice(0, -1)
      }
      const filterQuery = parseUrl(data)
      return hireHttp.delete('v1/profile' + filterQuery)
    },
    attemptUpdateUserProfile (_, data) {
      return hireHttp.put('v1/profile', data)
    },
    attemptUpdateUserGlobalInfo ({ commit }, data) {
      commit('updateUser', data)
    },
    attemptUpdateProfilePassword (_, data) {
      return hireHttp.put('v1/resetpassw', data)
    },
    attemptPostUserProfilePhoto (_, data) {
      return hireHttp.post('v1/store/images/users/avatar', data).then(({ data }) => {
        return data
      })
    },
    attemptPostDeleteAccount (_, data) {
      return hireHttp.post('v1/support/account/removal', data)
    },
    attemptImportUserResume (_, data) {
      return hireHttp.post('v1/resume/upload', data).then((response) => {
        return response
      })
    },
    attemptGetResumeResult (_, data) {
      return hireHttp.get('v1/resume/profile/' + data).then(({ data }) => {
        return data
      })
    },
    attemptRemoveUser () {
      return http.delete('v1/myself')
    },
    attemptUpdatePassword (_, data) {
      return http.put('v1/myself/password', parse(data))
    },
    attemptResetPassword () {
      return http.put('v1/myself/reset-password')
    },
    attemptNewPassword (_, data) {
      return hireHttp.post('v1/resetpassw/' + data.code, data.body)
    },
    attemptResetUserPassword (_, data) {
      return hireHttp.post('v1/resetpassword', data)
    },
    attemptReactiveUser () {
      return http.patch('v1/myself/reactivate')
    },
    submitUserSkillLevelChange ({ commit, dispatch, getters }, payload) {
      commit('updateUserSkill', payload)
      return dispatch('attemptSaveUserSkills', getters.getUser.skills.filter(skill => !skill.deleted))
    },
    attemptRemoveUserSkill ({ commit, dispatch, getters }, skill) {
      commit('removeUserSkill', skill)
      dispatch('attemptSaveUserSkills', getters.getUser.skills)
    },
    attemptGetMyselfSkills ({ commit }) {
      return http.get('v1/myself/skills').then((response) => {
        commit('updateUserSkills', response.data)
        return response
      })
    },
    attemptSynchronizeUser ({ commit }, payload) {
      return http.post('v1/auth/synchronize', payload.user).then((data) => {
        commit('syncronizeUserData', true)
        return data
      })
    },
    attemptRegisterUser (_, payload) {
      return hireHttp.post('v1/register/candidate', payload).then(({ data }) => {
        return data
      })
    },
    attemptGetThreadMessages (_, positionId) {
      return hireHttp.get('v1/messages/' + positionId).then(({ data }) => {
        return data
      })
    },
    attemptGetCountryCities (_, data) {
      return hireHttp.get(`v1/cities?country_code=${data.country}&query=${data.query}`).then(({ data }) => {
        return data
      })
    },
    attemptGetMeetDetails (_, id) {
      return hireHttp.get(`v1/meet/${id}`)
    },
    attemptPutResponseMeetInvitation (_, data) {
      return hireHttp.post('v1/meet/' + data.interviewId + '/' + data.response, { notes: data.notes }).then(({ data }) => {
        return data
      })
    },
    attemptPutMessage (_, data) {
      return hireHttp.post('v1/messages', data).then(({ data }) => {
        return data
      })
    },
    attemptPutReadMessage (_, data) {
      return hireHttp.put('v1/messages', data).then(({ data }) => {
        return data
      })
    },
    attemptPutResponseInvite (_, data) {
      return hireHttp.post('v1/applications/invites/feedback', data).then(({ data }) => {
        return data
      })
    },
    attemptPutTos () {
      return hireHttp.post('v1/-/tos').then(({ data }) => {
        return data
      })
    },
    attemptGetPositionSuggestionsHome (_, { user, aggregator }) {
      const getPayloadLocale = (params) => {
        const region = params.region
        const language = params.language

        if (aggregator.CompanyDomain === 'empregosagro') return 'pt-BR'
        return region ? (region === 'BRA' ? 'pt-BR' : 'en-US') : (language === 'pt' ? 'pt-BR' : 'en-US')
      }
      const language = user.language
      const payload = {
        salary: {
          minimum: 0,
          allowUnspecified: false
        },
        locale: getPayloadLocale(user),
        lang: language,
        length: 15,
        aggregator: aggregator.CompanyDomain
      }
      return hireHttp.get(`v1/suggestions?${parse(payload)}`).then(({ data }) => {
        return data
      })
    },
    attemptGetCourseSuggestionsHome (_, { aggregator }) {
      return hireHttp.get(`v1/courses/suggestions${aggregator.workSpaceType === 'aggregator' ? `?aggregator=${aggregator.CompanyDomain}` : ''}`).then(({ data }) => {
        return data.course
      })
    },
    attemptMoveWizardSteps ({ commit }, step) {
      commit('updateWizardSteps', step)
    },
    attemptCandidateProspect (_, { hash, body = {} }) {
      return hireHttp.post('v1/applications/invites/external/' + hash, body).then(({ data }) => {
        return data
      })
    },
    attemptUpdateProspectUserToPosition (_, hash) {
      return hireHttp.post('v1/applications/invites/external/accept/' + hash, {}).then(({ data }) => {
        return data
      })
    },
    attemptDeclineProspectInvite (_, hash) {
      return hireHttp.post('v1/applications/invites/external/decline/' + hash, {}).then(({ data }) => {
        return data
      })
    },
    attemptAutomaticLogin ({ commit }, syncData) {
      const aggregators = JSON.parse(localStorage.getItem('availableAggregators'))
      const workspaces = []
      staticWorkspaces.filter(workspace => workspace.subdomain === syncData.workspace || (aggregators && aggregators.length && (aggregators.findIndex(aggr => aggr.subdomain === workspace.subdomain) !== -1))).forEach(workspace => workspaces.push(workspace))

      localStorage.setItem('availableAggregators', JSON.stringify(workspaces))
      commit('updateUserToken', syncData.token)
      const individual = { ...syncData.user, type: 'individual' }
      commit('updateUser', individual)

      syncData.user.companies && syncData.user.companies.forEach(company => workspaces.push({ ...company, type: 'business' }))
      workspaces.push(individual)
      commit('updateUserWorkspaces', { force: true, workspaces: workspaces })
      commit('updateIndividualWorkspace', individual)
      return syncData
    },
    attemptGetOfferLetter () {
      return hireHttp.get('v1/pending_offer').then(({ data }) => {
        return data
      })
    },
    attemptAcceptOfferLetter (_, hash) {
      return hireHttp.get(`v1/offer_confirmation/${hash}`).then(({ data }) => {
        return data
      })
    },
    attemptDeclineOfferLetter (_, data) {
      return hireHttp.post(`v1/offer_decline/${data.hash}`, { notes: data.notes }).then(({ data }) => {
        return data
      })
    }
  }
}
